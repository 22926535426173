<template>
    <div :class="['list', 'dynamic', isMobile()?'mb-content':'']">
        <div class="item" v-for="item in newsList" :key="item.createTime" @click="goto(item)">
            <img :src="item.titleImg" alt="">
            <div class="content">
                <p class="title">{{item.title}}</p>
                <p class="text" v-html="item.content" v-if="!isMobile()"></p>
                <p class="time">{{item.createTime.substring(0,10)}}</p>
            </div>
        </div>
    </div>
</template>
<script>
import { getNews } from '@/api/news'
import { ref } from '@vue/reactivity'
import { useRouter } from 'vue-router'
export default {
    setup() {
        const newsList = ref([])
        const router = useRouter()
        const list = ref([])

        // 判断浏览器函数
        const isMobile = () => {
            if(window.navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)) {
                return true; // 移动端
            }else{
                return false; // PC端
            }
        }

        const getNewsList = async() => {
            let data = await getNews()
            if(data.status === 200) {
                list.value = data.data.data
                let arr = []
                // newsList.value
                let imgReg = /<img.*?(?:>|\/>)/gi;
                for (const el of list.value) {
                    let obj = {...el}
                    obj.content = obj.content.replace(imgReg,'')
                    arr.push(obj)
                }
                newsList.value = arr
            }
        }
        getNewsList()

        const goto = (item) => {
            for (const el of list.value) {
                if(el.id == item.id) {
                    sessionStorage.setItem('detail', JSON.stringify(el))
                }
            }
            router.push({
                name: "Detail"
            })
        }

        return {
            newsList,
            goto,
            isMobile
        }
    },
}
</script>
<style lang="scss">
    .dynamic {
        .text {
            p,span{
                color: #878787 !important;
                line-height: 26px !important;
                font-size: 16px !important;
            }
        }
    }
</style>
<style lang="scss" scoped>
    .list {
        margin: 40px 25px;
        .item {
            display: flex;
            margin-bottom: 50px;
            img {
                width: 270px;
                height: 147px;
                border-radius: 5px;
                vertical-align: top;
                margin-right: 30px;
            }
            .content {
                display: flex;
                flex-direction: column;
                justify-content: center;
                .title {
                    font-size: 18px;
                    display: -webkit-box;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    -webkit-box-orient: vertical;
                    word-break: break-word;
                    word-wrap: break-word;
                    -webkit-line-clamp: 1;
                }
                .text {
                    color: #878787;
                    line-height: 26px;
                    margin: 15px 0;
                    display: -webkit-box;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    -webkit-box-orient: vertical;
                    word-break: break-word;
                    word-wrap: break-word;
                    -webkit-line-clamp: 2;
                    p,span{
                        color: #878787 !important;
                        line-height: 26px !important;
                        font-size: 16px !important;
                    }
                }
                .time {
                    color: #878787;
                }
            }
        }
    }
    .mb-content {
        margin: 0.3rem;
        margin-bottom: 2rem;
        .item {
            padding-bottom: 0.3rem;
            border-bottom: 1px solid #f1f1f1;
            margin-bottom: 0.3rem;
            img {
                width: 2rem;
                height: 1.45rem;
                margin-right:0.3rem
            }
            .content { 
                .title {
                    font-size: 0.28rem;
                    display: -webkit-box;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    -webkit-box-orient: vertical;
                    word-break: break-word;
                    word-wrap: break-word;
                    -webkit-line-clamp: 2;
                }
                .time {
                    font-size: 0.28rem;
                    margin-top: 0.35rem;
                }
            }
        }
        .item:last-child {
            border: none;
        }
    }
</style>